<template>
  <div class="table-responsive">
    <table class="table table-row-bordered">
      <thead class="fs-6 fw-bolder border-bottom">
        <th>Portal</th>
        <th>2014</th>
        <th>2019</th>
      </thead>
      <tbody>
        <tr v-for="(item, i) in tableData" :key="i">
          <td class="">
            <img class="img-fluid" :src="item.img" alt="Logo Partai" />
            <span class="text-primary"> {{ item.name }} </span>
          </td>
          <td class="align-middle">
            <div
              class="bg-default"
              style="height: 1vw"
              :style="{ maxWidth: item.beforePercent + '%' }"
            ></div>
            <br />
            <span class="text-muted">
              {{ Intl.NumberFormat().format(item.beforeAmount) }} Suara
            </span>
          </td>
          <td class="align-middle">
            <div
              class="bg-default"
              style="height: 1vw"
              :style="{ maxWidth: item.afterPercent + '%' }"
            ></div>
            <br />
            <span class="text-muted">
              {{ Intl.NumberFormat().format(item.afterAmount) }} Suara
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "MapComparison-Pileg",
  setup() {
    const store = useStore();
    store.commit("SET_ACTIVE_MENU", "map-comparison");

    const path = window.location.origin + "/";

    const tableData = ref([
      {
        img: path + "other/png/partai/pdip.png",
        name: "PDI Perjuangan",
        beforeAmount: 23255,
        beforePercent: 50,
        afterAmount: 25966,
        afterPercent: 60,
      },
      {
        img: path + "other/png/partai/golkar.png",
        name: "Golkar",
        beforeAmount: 31134,
        beforePercent: 70,
        afterAmount: 24574,
        afterPercent: 60,
      },
      {
        img: path + "other/png/partai/pks-1.png",
        name: "PKS",
        beforeAmount: 12125,
        beforePercent: 50,
        afterAmount: 23466,
        afterPercent: 80,
      },
    ]);

    return {
      tableData,
    };
  },
});
</script>
